<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Uživatelé - Administrátoři - detail <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body" v-if="detail !== undefined && detail !== null">
      <dl class="row">
        <dt class="col-xl-12 text-center">
          <cui-avatar :src="$avatarVectorPath + detail.avatar" :size="110" :svg="true"/>
        </dt>
        <dt class="col-xl-12 text-center mt-2">
          <h2><strong>{{detail.uer_first_name}} {{detail.uer_last_name}}</strong></h2>
        </dt>
        <dt class="col-xl-3">E-mail:</dt>
        <dd class="col-xl-9">{{detail.email}}</dd>
        <dt class="col-xl-3">Křestní jméno:</dt>
        <dd class="col-xl-9">{{detail.uer_first_name}}</dd>
        <dt class="col-xl-3">Příjmení:</dt>
        <dd class="col-xl-9">{{detail.uer_last_name}}</dd>
        <dt class="col-xl-3">Datum registrace:</dt>
        <dd class="col-xl-9">{{this.toLocaleDate(detail.created_at)}}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import CuiAvatar from '@/components/CleanUIComponents/Avatar'

export default {
  components: { ActionTools, CuiAvatar },
  data: function () {
    return {
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/user/admin',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    detail: function() {
      return this.$store.getters['userAdmin/getDetail']
    },
  },
  methods: {
    initDetail() {
      this.$store.dispatch('userAdmin/getOne', this.id)
        .catch(() => {})
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
  },
  created() {
    this.initDetail()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.id = newId
      this.initDetail()
    },
  },
}
</script>
